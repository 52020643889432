// App.js
import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Mission from './components/Mission';
import Features from './components/Features';
import CTA from './components/CTA';
import './styles/App.css';

function App() {
  return (
    <div className="app">
      <Navbar />
      <Hero />
      <Mission />
      <Features />
      <CTA />
    </div>
  );
}

export default App;
// Features.js
import React from 'react';
import { ArrowRight } from 'lucide-react';
import '../styles/Features.css';

const Features = () => {
  return (
	<section className="features">
	  <div className="container">
		<h2>Program Features</h2>
		<div className="features-grid">
		  <div className="feature-column">
			<h3>Comprehensive Training</h3>
			<ul>
			  <li>
				<ArrowRight size={20} />
				Personalized skill development plans
			  </li>
			  <li>
				<ArrowRight size={20} />
				One-on-one coaching sessions
			  </li>
			  <li>
				<ArrowRight size={20} />
				Performance tracking and analytics
			  </li>
			</ul>
		  </div>
		  <div className="feature-column">
			<h3>Support Systems</h3>
			<ul>
			  <li>
				<ArrowRight size={20} />
				Peer learning groups
			  </li>
			  <li>
				<ArrowRight size={20} />
				Financial support
			  </li>
			  <li>
				<ArrowRight size={20} />
				Regular progress reviews
			  </li>
			</ul>
		  </div>
		</div>
	  </div>
	</section>
  );
};

export default Features;
// Mission.js
import React from 'react';
import { Target, Users, Trophy } from 'lucide-react';
import Card from './Card';
import '../styles/Mission.css';

const Mission = () => {
  const cards = [
	{
	  icon: Target,
	  title: 'Targeted Development',
	  description: 'Custom training programs designed to maximize individual potential and achieve specific goals'
	},
	{
	  icon: Users,
	  title: 'Mentorship',
	  description: 'Providing guidance from experienced professionals who are committed to your success'
	},
	{
	  icon: Trophy,
	  title: 'Achievement',
	  description: ' Supporting youth to cultivate skills, foster growth, and make their dreams a reality'
	}
  ];

  return (
	<section className="mission">
	  <div className="container">
		<h2>Our Mission</h2>
		<div className="mission-cards">
		  {cards.map((card, index) => (
			<Card key={index} {...card} />
		  ))}
		</div>
	  </div>
	</section>
  );
};

export default Mission;
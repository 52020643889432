import React from 'react';
import Button from './Button';
import '../styles/Hero.css';

const Hero = () => {
  return (
	<div className="hero">
	  <div className="hero-overlay"></div>
	  <img 
		src="/racing-car.png" 
		alt="Racing car with mentor" 
		className="hero-image"
	  />
	  <div className="hero-content">
		<h2>Empowering individuals to reach their full potential through tailored training and support</h2>
	  </div>
	</div>
  );
};

export default Hero;
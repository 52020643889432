// CTA.js
import React from 'react';
import Button from './Button';
import '../styles/CTA.css';

const CTA = () => {
  return (
	<section className="cta">
	  <div className="container">
		<h2>Let's unlock new opportunities together!</h2>
		
	  </div>
	</section>
  );
};

export default CTA;
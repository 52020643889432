import React from 'react';
import '../styles/Card.css';

const Card = ({ icon: Icon, title, description }) => {
  return (
	<div className="card">
	  <div className="card-icon">
		<Icon size={48} />
	  </div>
	  <h3 className="card-title">{title}</h3>
	  <p className="card-description">{description}</p>
	</div>
  );
};

export default Card;
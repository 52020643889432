// components/Navbar.js
import React from 'react';
import '../styles/Navbar.css';

const Navbar = () => {
  return (
	<nav className="navbar">
	  <div className="navbar-container">
		<div className="navbar-brand">
		  <img 
			src="/logo.png" 
			alt="High Performance Development Program Logo" 
			className="navbar-logo"
		  />
		  <div className="navbar-titles">
			<h1 className="navbar-title">High Performance Development Program</h1>
		  </div>
		</div>
		<ul className="navbar-links">
		
		  <li>
			<button className="navbar-button">Contact</button>
		  </li>
		</ul>
	  </div>
	</nav>
  );
};

export default Navbar;